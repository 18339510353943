import { useMutation } from "@apollo/client";
import EmailCheckForm from "auth/forms/EmailCheckForm";
import React, { useState } from "react";
import { useAuth as useSSOAuth } from "react-oidc-context";
import { Navigate, useNavigate } from "react-router-dom";
import BaseSSOPageContainer from "sso/components/BaseSSOPageContainer";
import styled from "styled-components";

import { useAuth } from "collection/graphql/auth";
import ssoLookup from "collection/graphql/auth/mutations/ssoLookup";

import { Button } from "components/fl-ui";
import { GreyColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";

const SignIn = styled(({ className }) => {
  const auth = useSSOAuth();
  const [email, setEmail] = useState("");
  const [view, setView] = useState("collectEmail");
  const [lookup] = useMutation(ssoLookup, {
    fetchPolicy: "no-cache",
  });

  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  const setUserEmail = async (email) => {
    const {
      data: {
        strategy: { ssoOnly },
      },
    } = await lookup({
      variables: {
        input: {
          email,
        },
      },
    });

    if (ssoOnly) {
      setEmail(email);
      setView("sign-in");
    } else {
      navigate(`/login?login_hint=${encodeURIComponent(email)}`);
    }
  };

  const goToBushelIdentity = () => {
    void auth.signinRedirect({
      login_hint: email,
      show_cancel_button: true,
    });
  };

  return (
    <BaseSSOPageContainer title="Next Generation Farm Management Software">
      <div className={className}>
        {view === "sign-in" ? (
          <>
            <Button className="sign-in" color="primary" onClick={goToBushelIdentity}>
              Sign In
            </Button>
            <Button className="sign-up" color="primary" hollow onClick={goToBushelIdentity}>
              Sign Up
            </Button>
            <div className="grey-text">
              <p>
                {`By creating a Bushel Farm account, you are accepting Bushel's `}
                <a href="https://bushelpowered.com/trust-center/" target="_blank" rel="noreferrer">
                  privacy policy and terms of use
                </a>
                .
              </p>
            </div>
          </>
        ) : (
          <div>
            <EmailCheckForm setSSOUserEmail={setUserEmail} />
          </div>
        )}
      </div>
    </BaseSSOPageContainer>
  );
})`
  width: 100%;

  .sign-in,
  .sign-up {
    margin: ${Spacing.xxsmall} 0;
    width: 100%;
  }

  .grey-text {
    color: ${GreyColors.smoke70};
    font-size: 0.8rem;
    margin-top: ${Spacing.large};

    a {
      color: ${GreyColors.smoke70};
      text-decoration: underline;
    }
  }
`;

export default SignIn;
