"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
exports.default = (0, client_1.gql) `
  query getCurrentUserMembership {
    currentMembership {
      enterprise {
        id
        name
      }
      role {
        id
        name
      }
      user {
        id
        name
      }
    }
  }
`;
