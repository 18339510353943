import { gql } from "@apollo/client";

/**
 * @see CurrentUser
 */
export default gql`
  query getCurrentUser {
    currentUser: getCurrentUser @rest(type: "CurrentUser", path: "/users/me") {
      id
      created
      email
      firstName
      icon
      landingPage
      lastLogin
      lastName
      name
      notes
      phone
      policy
      ssoOnly
      updated
      uuid
      role
    }
  }
`;
