import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import React, { Fragment, Suspense, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useShallow } from "zustand/react/shallow";

import { AUTHENTICATED, LEGACY, SSO, UNKNOWN, useAuthStore } from "collection/graphql/auth/hooks/AuthStore";
import getCurrentUser from "collection/graphql/auth/queries/getCurrentUser";
import { marketingClient } from "collection/graphql/client";
import useAsync from "hooks/useAsync";

import ErrorBoundary from "components/fl-ui/Layout/ErrorBoundary";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const useOnce = (condition) => {
  const [result, setResult] = useState(condition);
  useEffect(() => {
    if (condition && !result) {
      setResult(true);
    }
  }, [condition]);

  return result;
};

const SSOProvider = ({ children }) => {
  return <Fragment key="SSOProvider">{children}</Fragment>;
};

const LegacyProvider = ({ children }) => {
  /* query to set up polling */
  useQuery(getCurrentUser, {
    client: marketingClient,
    pollInterval: 1000 * 60 * 1000,
  });

  return <Fragment key="LegacyProvider">{children}</Fragment>;
};

const AuthContextProvider = ({ children }) => {
  const auth = useAuth();
  const { isAuthenticated } = auth;

  const store = useAuthStore();
  const authStrategy = useAuthStore(useShallow((state) => state.strategy));
  store.initialize(auth);
  const hasLoadedInitially = useOnce(!auth.isLoading);

  /**
   * Try to determine which auth strategy to use.  Use:
   *  SSO – if the user is currently sso-authenticated OR
   *        they are authenticated via a session cookie and DO have the ssoOnly flag
   *  LEGACY – if they are authenticated via a session cookie and DO NOT have the ssoOnly flag
   *  UNKNOWN – if the user is not authenticated in any way. Further steps are required.
   *            See {@link ProtectedRouteLayout}.
   */
  useAsync(async () => {
    if (hasLoadedInitially) {
      if (isAuthenticated) {
        store.setStrategy(SSO);
        store.setIsAuthenticated(true);
      } else {
        await marketingClient
          .query({
            query: getCurrentUser,
            fetchPolicy: "no-cache",
          })
          .then(
            ({ data }) => {
              const strategy = data.currentUser.ssoOnly ? SSO : LEGACY;
              store.setStrategy(strategy);
              store.setIsAuthenticated(true);
              store.setStatus(AUTHENTICATED);
            },
            () => {
              store.setStrategy(UNKNOWN);
            }
          );
      }
    }
  }, [hasLoadedInitially, isAuthenticated]);

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingWrapper />}>
        {!authStrategy && <LoadingWrapper />}
        {authStrategy === SSO && <SSOProvider>{children}</SSOProvider>}
        {authStrategy === LEGACY && <LegacyProvider>{children}</LegacyProvider>}
        {authStrategy === UNKNOWN && <Fragment key="Unknown">{children}</Fragment>}
      </Suspense>
    </ErrorBoundary>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default AuthContextProvider;
