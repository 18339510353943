import { App } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { Capacitor, CapacitorCookies, Plugins } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Geolocation } from "@capacitor/geolocation";
import { Preferences } from "@capacitor/preferences";
import { Share } from "@capacitor/share";
import { SplashScreen } from "@capacitor/splash-screen";
import { BushelFarmCapacitorPlugin } from "@farmlogs/bushel-farm-capacitor-plugin";
import { getAppRedirectUrl } from "app/getAppRedirectUrl";
import { oneSignalConfigure, oneSignalLogIn, oneSignalLogOut, oneSignalOptIn } from "mobile/mobileNotifications";
import semverGte from "semver/functions/gte";

export const appVersion = async () => {
  if (isCapacitor5) {
    const appInfo = await App.getInfo();
    return appInfo.version;
  } else {
    // old capacitor 2 version
    const deviceInfo = await window.Capacitor.nativePromise("Device", "getInfo");
    return deviceInfo?.appVersion ?? "4.0.0";
  }
};

export const browserOpenUrl = async (url) => {
  if (isCapacitor5) {
    return Browser.open({ url: url });
  } else {
    return window.Capacitor.nativePromise("Browser", "open", { url: url });
  }
};

export const cookiesClearAll = async () => {
  await CapacitorCookies.clearAllCookies();
};

export const geolocationClearWatch = async (callbackId) => {
  if (isCapacitor5) {
    return Geolocation.clearWatch({ id: callbackId });
  } else {
    return window.Capacitor.nativePromise("Geolocation", "clearWatch", { id: callbackId });
  }
};

export const geolocationWatchPosition = async (options, callback) => {
  if (isCapacitor5) {
    return Geolocation.watchPosition(options, callback);
  } else {
    return window.Capacitor.nativePromise("Geolocation", "watchPosition", options, callback);
  }
};

export const deviceGetInfo = async () => {
  if (isCapacitor5) {
    return Device.getInfo();
  } else {
    return window.Capacitor.nativePromise("Device", "getInfo");
  }
};

export const directoryCache = () => {
  return Directory.Cache;
};

export const fileRmdir = async (options) => {
  if (isCapacitor5) {
    return Filesystem.rmdir(options);
  } else {
    return window.Capacitor.nativePromise("Filesystem", "rmdir", options);
  }
};

export const fileWrite = async (options) => {
  if (isCapacitor5) {
    return Filesystem.writeFile(options);
  } else {
    return window.Capacitor.nativePromise("Filesystem", "writeFile", options);
  }
};

/**
 * @return {"android"|"ios"|"web"}
 */
export const getPlatform = () => {
  return Capacitor.getPlatform();
};

export const isCapacitor5 = Capacitor.isPluginAvailable("StatusBar");

export const isFullWeb = async () => {
  // App version which removed custom bushel farm plugin in favor of using all official plugins
  const fullWebVersion = "7.1.0";

  try {
    const appInfo = await App.getInfo();
    return semverGte(appInfo.version, fullWebVersion);
  } catch {
    // old capacitor 2 version
    return false;
  }
};

export const isNative = () => Capacitor.isNativePlatform();

export const logoutUser = async () => {
  if (await isFullWeb()) {
    oneSignalLogOut();
    await CapacitorCookies.clearAllCookies();
    Preferences.clear();
  } else if (isCapacitor5) {
    return BushelFarmCapacitorPlugin.logoutUser();
  } else {
    return Plugins.User?.logoutUser();
  }
};

export const mobileAppConfigureOnLaunch = async () => {
  if (isNative() && (await isFullWeb())) {
    oneSignalConfigure();
    SplashScreen.hide();
    await setupDeepLinkListeners();
  }
};

const setupDeepLinkListeners = async () => {
  App.addListener("appUrlOpen", (data) => {
    const appRedirectUrl = getAppRedirectUrl(data.url);
    if (appRedirectUrl) {
      window.location.replace(appRedirectUrl);
    }
  });
};

export const mobileNotificationLogIn = async (currentUser) => {
  if (!isNative()) {
    throw new Error("Not implemented on web");
  } else if (!(await isFullWeb())) {
    throw new Error("Not implemented in this mobile version");
  }

  return oneSignalLogIn(currentUser);
};

export const requestNotificationPermission = async () => {
  if (await isFullWeb()) {
    oneSignalOptIn();
  } else if (isCapacitor5) {
    BushelFarmCapacitorPlugin.requestNotificationPermission();
  } else {
    Plugins.MobileNotification?.requestNotificationPermission?.();
  }
};

export const share = async (options) => {
  if (isCapacitor5) {
    return Share.share(options);
  } else {
    return window.Capacitor.nativePromise("Share", "share", options);
  }
};
