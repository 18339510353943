import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import _ from "lodash";

import { notificationsClient } from "collection/graphql/client";

/*
 * Queries
 */
// Notifications
const DISMISS_ALL_NOTIFICATIONS = gql([
  `
  mutation dismissAllNotifications {
    dismissAllUserNotifications {
      ok
    }
  }
`,
]);

const DISMISS_NOTIFICATION = gql([
  `
  mutation dismissNotification($id: Int!) {
    dismissUserNotification(notificationId: $id) {
      ok
    }
  }
`,
]);

const GET_USER_NOTIFICATIONS = gql([
  `
  query getUserNotifications {
    notifications: getUserNotifications {
      body
      created
      data
      id
      title
      type
      user {
        id
      }
    }
  }
`,
]);

// Notification subscriptions
const GET_USER_SUBSCRIPTIONS = gql([
  `
  query getUserSubscriptions {
    subscriptions: getUserSubscriptions {
      email
      notificationType
      options
      push
    }
  }
`,
]);

const SET_USER_SUBSCRIPTION = gql([
  `
  mutation setUserNotification($notificationType: String!, $push: Boolean!, $email: Boolean!, $options: JSONObject) {
    subscribe(notificationType: $notificationType, push: $push, email: $email, options: $options) {
      ok
      subscription {
        notificationType
        push
        email
        options
      }
    }
  }
`,
]);

/*
 * Methods
 */
const dismissAllNotifications = graphql(DISMISS_ALL_NOTIFICATIONS, {
  props: ({ mutate }) => ({
    dismissAllNotifications: () => {
      notificationsClient.writeQuery({
        query: GET_USER_NOTIFICATIONS,
        data: {
          notifications: [],
        },
      });

      mutate();
    },
  }),
  options: {
    refetchQueries: ["getUserNotifications"],
  },
});

const dismissNotification = graphql(DISMISS_NOTIFICATION, {
  props: ({ mutate }) => ({
    dismissNotification: (id) => {
      const { notifications } = notificationsClient.readQuery({
        query: GET_USER_NOTIFICATIONS,
      });
      notificationsClient.writeQuery({
        query: GET_USER_NOTIFICATIONS,
        data: {
          notifications: _.reject(notifications, { id: `${id}` }),
        },
      });

      mutate({
        variables: { id },
      });
    },
  }),
  options: {
    refetchQueries: ["getUserNotifications"],
  },
});

const pollUserNotifications = graphql(GET_USER_NOTIFICATIONS, {
  options: {
    client: notificationsClient,
    pollInterval: 1000 * 60 * 5, // every 5 minutes
  },
});

const getUserSubscriptions = graphql(GET_USER_SUBSCRIPTIONS, {
  options: {
    client: notificationsClient,
  },
});

const setUserSubscription = graphql(SET_USER_SUBSCRIPTION, {
  props: ({ mutate }) => ({
    /**
     * Sets a notification option by type
     * @param {Object} variables
     * @param {"imagery"|"news"|"market-prices"|"rainfall"|"work-orders"} variables.type
     * @param {Boolean} variables.push
     * @param {Boolean} variables.email
     * @param {Object} [variables.options={}]
     * @returns {Promise}
     */
    setUserSubscription: (variables) =>
      mutate({
        variables,
        refetchQueries: ["getUserSubscriptions"],
      }),
  }),
  options: {
    client: notificationsClient,
  },
});

export {
  dismissAllNotifications,
  dismissNotification,
  pollUserNotifications,
  getUserSubscriptions,
  setUserSubscription,
};
