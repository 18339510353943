import { useQuery } from "@apollo/client";
import { css } from "aphrodite";
import { SalesEmailLink, SalesPhoneLink } from "billing/lib";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";
import WarningBanner from "settings/components/WarningBanner";
import styles from "settings/styles";

import { getCurrentUserMembership } from "collection/graphql/enterprise/queries";
import useContactInfo from "hooks/useContactInfo";
import useUserLimits from "hooks/useUserLimits";
import { fetch } from "lib/ajax";

import { Button } from "components/fl-ui";
import FileAttachments from "components/fl-ui/Attachments/FileAttachments";
import { FormGroup, Input, Select, TextArea } from "components/fl-ui/Form";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";

const getMailtoUri = (email) => {
  const params = [
    ["subject", "Having trouble adding user to my account"],
    [
      "body",
      `\n We look forward to replying to you! Below is our description of the Bushel Farm issue that you encountered:
      \n \n I'm trying to add a person to my Bushel Farm account and I'm getting this error message:
      \n "Someone has already signed up for Bushel Farm with this email address. Try using a different address."`,
    ],
  ];

  const query = params.map(([key, value]) => `${key}=${encodeURIComponent(value)}`);
  return `mailto:${email}?${query.join("&")}`;
};

const UniqueErrorMessage = () => {
  const { supportEmail } = useContactInfo();

  return (
    <>
      <span className={css(styles.errorText)}>Unable to add person</span>
      <span className={css(styles.lightText)}>
        {"Still need help? "}
        <Link className={css(styles.blueText)} to={getMailtoUri(supportEmail)} target="_top">
          Contact Customer Success
        </Link>
      </span>
    </>
  );
};

const UserLimitBanner = ({ role }) => {
  if (role === "service_provider") {
    const message = (
      <>
        Your account has exceeded the service provider limit for your plan. Contact us to build a customized plan
        designed specifically for larger operations. Call <SalesPhoneLink /> or email <SalesEmailLink />.
      </>
    );

    return <WarningBanner icon="personWarning" message={message} />;
  }

  return null;
};

const isValid = (data) => {
  const requiredFields = ["firstName", "lastName", "role"];
  if (data.role !== "no_access") {
    requiredFields.push("email");
  }
  if (data.role === "service_provider") {
    requiredFields.push("phone");
  }

  return _.every(requiredFields, (name) => _.trim(data[name]));
};

/**
 * Returns a promise that resolves if the user exists and rejects if they do not exist.
 * @param {String} email
 * @return {Promise<boolean>}
 */
const isAcceptableEmail = async (email) => {
  try {
    headers["Content-Type"] = "application/json";
    const response = await fetch("/v2.0/lookup/enterprise", {
      body: JSON.stringify({ email }),
      cache: "no-store",
      method: "POST",
    });

    return !response.ok;
  } catch (error) {
    return false;
  }
};

const AddEditUserModal = ({ isEditingSelf, data, isNew, onCancel, onSave }) => {
  const { data: userMembership } = useQuery(getCurrentUserMembership);
  const role = userMembership?.currentMembership?.role?.name;
  const canEditAdmin = role === "admin";

  const { canAddRole, enforce } = useUserLimits();

  const roleOptions = [
    { disabled: !canEditAdmin, id: "admin", label: "Admin", value: "admin" },
    { id: "manager", label: "Manager", value: "manager" },
    { id: "contributor", label: "Contributor", value: "contributor" },
    { id: "no_access", label: "No access", value: "no_access" },
  ];

  const [formData, setFormData] = useState(data);
  const [isDisabled, setIsDisabled] = useState(!isValid(formData));
  const [isLoading, setIsLoading] = useState(false);
  const [showWarningBanner, setShowWarningBanner] = useState(false);
  const [showUserLimitBanner, setShowUserLimitBanner] = useState(false);
  const isServiceProvider = formData.role === "service_provider";

  const images = [];
  if (formData.icon) {
    images.push({ filepickerId: formData.icon });
  }

  useEffect(() => {
    if (!data.id) {
      setShowUserLimitBanner(!canAddRole(formData.role));
    }
  }, [enforce, formData.role]);

  const handleChange = (data) => {
    if ("email" in data) {
      setShowWarningBanner(false);
    }

    const newFormData = {
      ...formData,
      ...data,
    };
    setFormData(newFormData);
    setIsDisabled(!isValid(newFormData));
  };

  const handleSaveClick = async () => {
    if ((isNew || data.email !== formData.email) && formData.role !== "no_access") {
      setIsDisabled(true);
      setIsLoading(true);
      const canUseEmail = await isAcceptableEmail(formData.email);
      if (canUseEmail) {
        onSave(formData);
      } else {
        setShowWarningBanner(true);
        setIsDisabled(false);
        setIsLoading(false);
      }
    } else {
      onSave(formData);
    }
  };

  let buttonText = "Save";
  let title = "Edit person";
  if (isServiceProvider) {
    title = "Edit Service Provider";
    if (!data.id) {
      buttonText = "Add Service Provider";
      title = "Add Service Provider";
    }
  } else if (!formData.id) {
    buttonText = "Add person";
    title = "Add person";
  }

  // allows filling in fields on Add, but only on edit if you are editing yourself
  const disableEdit = !(!formData.id || isEditingSelf);

  return (
    <Modal>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
        <CloseX onClick={onCancel} />
      </ModalHeader>

      <ModalBody>
        {isServiceProvider && (
          <p className={css(styles.userModalBlurb)}>
            This role is reserved for third-party service providers. Service Provider roles cannot access your Bushel
            Farm account and are limited to resolving work via an activities dashboard.
          </p>
        )}

        {showUserLimitBanner && <UserLimitBanner role={data.role} />}
        {showWarningBanner && <WarningBanner message={<UniqueErrorMessage />} />}

        <Row>
          <Col xs md={6}>
            <FormGroup label="First name">
              <Input
                defaultValue={data.firstName || ""}
                name="firstName"
                disabled={disableEdit}
                onChange={(e, data) => handleChange(data)}
              />
            </FormGroup>
          </Col>
          <Col xs md={6}>
            <FormGroup label="Last name">
              <Input
                defaultValue={data.lastName || ""}
                name="lastName"
                disabled={disableEdit}
                onChange={(e, data) => handleChange(data)}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs md={6}>
            <FormGroup label="Email">
              <Input
                defaultValue={data.email || ""}
                name="email"
                disabled={disableEdit}
                onChange={(e, data) => handleChange(data)}
              />
            </FormGroup>
          </Col>
          <Col xs md={6}>
            {isServiceProvider && (
              <FormGroup label="Phone">
                <Input defaultValue={data.phone || ""} name="phone" onChange={(e, data) => handleChange(data)} />
              </FormGroup>
            )}

            {!isServiceProvider && (
              <FormGroup label="Role">
                <Select
                  disabled={isEditingSelf}
                  name="role"
                  onChange={(e, data) => handleChange(data)}
                  options={roleOptions}
                  value={formData.role}
                />
              </FormGroup>
            )}
          </Col>
        </Row>

        <Row>
          <Col xs>
            <FormGroup label="Notes (optional)">
              <TextArea
                defaultValue={data.notes || ""}
                name="notes"
                disabled={disableEdit}
                onChange={(e, data) => handleChange(data)}
              />
            </FormGroup>
          </Col>
        </Row>

        {!disableEdit && (
          <Row>
            <Col xs>
              <FormGroup label="Photo (optional)">
                <FileAttachments
                  accept={["image/*"]}
                  files={images}
                  maxFiles={1}
                  onChange={([image]) => handleChange({ icon: image ? image.filepickerId : null })}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
      </ModalBody>

      <ModalFooter className={css(styles.buttonGroup, styles.reverseRow)}>
        <div className={css(styles.buttonGroup)}>
          <Button className={css(styles.rightMargin)} color="primary" link onClick={onCancel}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={isDisabled || showUserLimitBanner}
            loading={isLoading}
            onClick={handleSaveClick}
          >
            {buttonText}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AddEditUserModal;
