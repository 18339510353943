import { BaseElement } from "@farmlogs/fl-ui";
import useDocumentTitle from "app/hooks/useDocumentTitle";
import _ from "lodash";
import { CommentaryCard } from "marketing/cards";
import AdvisorDisclaimer from "marketing/components/AdvisorDisclaimer";
import AdvisorSponsorImage from "marketing/components/AdvisorSponsorImage";
import moment from "moment";
import React from "react";
import styled from "styled-components";

import { getAdvisorCommentaries, getRssEntry, useFarmAdvisorConfig } from "collection/graphql/advisor";
import useAdvisorQuery from "hooks/useAdvisorQuery";

import { GreyColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";

const getCommentaryTitle = (commentary) => {
  if (commentary?.title) {
    return commentary.title;
  }

  switch (commentary?.__typename) {
    case "DailyFlashCommentary":
      return "Daily Flash";

    case "OtherCommentary":
      return "Commentary";

    case "USDAMarketUpdateCommentary":
      return "USDA Market Update";

    case "WeeklyReportCommentary":
      return "Weekly Report";

    default:
      return "Commentary";
  }
};

/**
 * @param {object[]} activeRecommendations an array of `ActiveRecommendationCommentary` objects
 * @return {object[]} an sorted, filtered array of `ActiveRecommendationCommentary` objects
 */
export const filterAdditionalActiveRecommendations = (activeRecommendations) => {
  const filteredRecommendations = _.uniqBy(activeRecommendations, "commodity.id");
  return _.sortBy(filteredRecommendations, "commodity.ordinal");
};

const CargillElevate = styled(({ className }) => {
  const advisorConfig = useFarmAdvisorConfig().data;
  const disableRss = advisorConfig.disallowedCommentaryTypes.includes("RSSEntry");
  const { tabTitle } = advisorConfig.nav;
  useDocumentTitle(tabTitle);

  const podcastData = useAdvisorQuery(getRssEntry, { skip: disableRss }).data?.rssEntry;
  const { commentary } = useAdvisorQuery(getAdvisorCommentaries).data || {};

  const partitionedData = _.partition(commentary, { __typename: "ActiveRecommendationCommentary" });
  const activeRecommendations = filterAdditionalActiveRecommendations(partitionedData[0]);
  const additionalCommentary = partitionedData[1];

  return (
    <div className={className}>
      <BaseElement
        alignItems={{ _: "flex-start", desktop: "center" }}
        as="h4"
        display="flex"
        flexDirection={{ _: "column", desktop: "row" }}
        gap="5px"
      >
        Your Bushel Farm subscription is sponsored by
        <AdvisorSponsorImage />
      </BaseElement>

      <h2>Active Cash Recommendations</h2>
      <>
        {activeRecommendations?.map((commentary) => {
          const currentDate = moment().startOf("day").hour(12);
          const formattedPublishDate = moment(commentary.publishDate + "T12:00:00");
          const isNew = currentDate.diff(formattedPublishDate, "days") < 3;

          return (
            <CommentaryCard
              key={commentary.id}
              isNew={isNew}
              headerToggle
              title={commentary.commodity.name + " Recommendation"}
              date={moment(commentary.publishDate).format("MMMM D, YYYY") || ""}
              body={commentary.content}
            />
          );
        })}
      </>

      <h2>Market Commentary</h2>
      <>
        {podcastData && (
          <CommentaryCard
            audioTitle={podcastData.title}
            audioSrc={podcastData.links[0].href}
            body={podcastData.summary}
            date={moment(podcastData.published).format("MMMM D, YYYY") || ""}
            expandButtonText="View All Show Notes"
            title="Market Update"
          />
        )}

        {additionalCommentary?.map((commentary) => (
          <CommentaryCard
            assets={commentary.assets}
            body={commentary.content}
            date={commentary.publishDate ? moment(commentary.publishDate).format("MMMM D, YYYY") : ""}
            expandButtonText="View Full Report"
            key={commentary.id}
            title={getCommentaryTitle(commentary)}
            weeklyReportDetails={commentary?.details}
          />
        ))}
      </>

      <AdvisorDisclaimer className="disclaimer-text" />
    </div>
  );
})`
  h2 {
    margin: ${Spacing.xlarge} 0;
  }

  .disclaimer-text {
    margin: ${Spacing.large} 0;
    color: ${GreyColors.smoke70};
    font-style: italic;
    font-weight: 300;
    font-size: 13px;
  }
`;

export default CargillElevate;
