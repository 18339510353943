import React from "react";
import DashboardPage from "reports/routes/Dashboard";
import ReportDetail from "reports/routes/ReportDetail";

export default [
  {
    path: "/reports",
    element: <DashboardPage />,
  },

  {
    path: "/reports/:reportId",
    element: <ReportDetail />,
  },
];
