import InventoryCollectionsLoader from "inventory/components/InventoryCollectionsLoader";
import React from "react";
import { Outlet, redirect } from "react-router-dom";

import useHistoricalRainfall from "collection/graphql/weather/hooks/useHistoricalRainfall";
import useRecentRainfall from "collection/graphql/weather/hooks/useRecentRainfall";
import OnboardingDashboard from "modules/fields/onboarding/OnboardingDashboard";

import FieldAdd from "fields/components/FieldAdd";
import FieldAspect from "fields/components/FieldAspect";
import FieldDetail from "fields/components/FieldDetail";
import FieldsIndexPage from "fields/pages/FieldsIndexPage";

const RainfallPollingLoader = () => {
  useRecentRainfall();
  useHistoricalRainfall();

  return <Outlet />;
};

const InventoryCollectionsDataGuard = () => {
  return (
    <InventoryCollectionsLoader>
      <Outlet />
    </InventoryCollectionsLoader>
  );
};

export default [
  {
    element: <RainfallPollingLoader />,
    children: [
      {
        path: "/fields",
        element: <FieldsIndexPage />,
      },

      {
        element: <InventoryCollectionsDataGuard />,
        children: [
          {
            path: "/fields/onboarding-dashboard/:id",
            element: <OnboardingDashboard />,
          },

          {
            path: "/fields/:id",
            element: <FieldDetail />,
          },

          {
            path: "/fields/add",
            element: <FieldAdd />,
          },

          {
            path: "/fields/:id/edit",
            element: <FieldAdd />,
          },

          {
            path: "/fields/:id/:aspect",
            loader: ({ params: { aspect, id } }) => {
              const validAspects = [
                "activities",
                "attachments",
                "crop_history",
                "gdd",
                "loads",
                "rainfall",
                "scouting",
                "yield",
              ];

              if (validAspects.includes(aspect)) {
                return null;
              }

              return redirect(`/fields/${id}`);
            },
            element: <FieldAspect />,
          },
        ],
      },
    ],
  },
];
