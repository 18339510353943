import { useLazyQuery } from "@apollo/client";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useAuthStore } from "collection/graphql/auth/hooks/AuthStore";
import { getCurrentUserMembership } from "collection/graphql/enterprise/queries";
import useAsync from "hooks/useAsync";

/** @name ProtectedRouteLayout */
const ProtectedRouteLayout = () => {
  const { isAuthenticated } = useAuthStore();

  const [executeQuery] = useLazyQuery(getCurrentUserMembership);
  const { loading, value: isValidEnterprise } = useAsync(async () => {
    const { error } = await executeQuery();
    return !error;
  });

  if (loading) {
    return null;
  }

  if (!isAuthenticated) {
    return <Navigate to="/sign_in" />;
  }

  if (!isValidEnterprise) {
    return <Navigate to="/sso" />;
  }

  return <Outlet />;
};

export default ProtectedRouteLayout;
