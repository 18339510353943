import React, { Suspense } from "react";
import styled from "styled-components";

import useViewType from "hooks/useViewType";

import ErrorBoundary from "components/fl-ui/Layout/ErrorBoundary";
import styleVars from "components/fl-ui/Layout/Navigation/styleVars";
import LoadingSpinner from "components/ui/loading";

const Loading = () => (
  <div className="loading-placeholder">
    <h2>Loading...</h2>
    <LoadingSpinner />
  </div>
);

const Main = styled(({ className, children }) => {
  return (
    <div id="main" className={`new-navigation ${className}`}>
      <div>
        <Suspense fallback={<Loading />}>
          {children || <Loading />}
        </Suspense>
      </div>
    </div>
  );
})`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: ${(props) =>
    props.displaySideNavPadding ? `0 0 0 ${styleVars.mainMenuWidth_desktop}` : "0 0 0 0"} !important;

  @media print {
    padding: 0 !important;
  }

  & > div {
    width: 100%;
    height: 100%;
    padding: 0;
  }
`;

const ContentContainer = ({ children }) => {
  const isDesktop = useViewType() === "desktop";

  return (
    <ErrorBoundary>
      <Main displaySideNavPadding={isDesktop}>{children}</Main>
    </ErrorBoundary>
  );
};

export default ContentContainer;
